import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
 

const FlexCard = (props) => {
  return (
    <div className="card">
      <div className={props.rowType}>
        <div className="col">
          <div className="card__image">
            <LazyLoadImage effect="blur" src={props.image} alt={props.imageAlt} />
          </div>
        </div>
        <div className="col">
          <div className="card__content">
            <h3>{props.header}</h3>
            <h6>{props.subheader}</h6>
            {props.text && <p>{props.text}</p>}
            {props.children}
            {props.phone && <a href={props.phone} target="_blank" rel="noopener noreferrer" className="phone-number" ><button className={`btn ${props.btnStyle} ${props.btnCustom || ""}`}>{props.btnText}</button></a> }
          </div>
        </div>
      </div>
    </div>
  );
};
export default FlexCard;
