import React from 'react';
import Layout from '../../components/motorrad/layout';
import SEO from '../../components/motorrad/seo';
import FlexCard from '../../components/motorrad/flexcard';
import GiftCardBackground from '../../components/giftcardbackground';
import settings from '../../../settings';


const topbanner = `${settings.IMAGES_BASE_URL}/images/motorrad/giftcards/moto-banner.jpg`;
const giftcardimage = `${settings.IMAGES_BASE_URL}/images/motorrad/giftcards/moto-gift-card.jpg`;
const holidayBanner = `${settings.IMAGES_BASE_URL}/images/motorrad/home/moto-holiday.jpg`;
const today = new Date();
const showHolidayBanner = 
  today >= new Date(2024,10,8) && 
  today < new Date(2025,0,1);
const GiftCards = () => {
  const getFirstCard = () => {
    if (showHolidayBanner) {
      return (
        <FlexCard
          rowType="row"
          image={holidayBanner}
          imageAlt="DASHING THROUGH THE DIRT"
          header="DASHING THROUGH THE DIRT"
          subheader="20% OFF A GIFT CARD"
          text="Make the holidays more epic with 20% off a gift card, good toward many classes and Experiences."
          btnText="CALL 888-345-4269 TO BOOK YOUR CLASS"
          btnStyle="btn-bordered"
          phone="tel:888-345-4269"
        >
          <p>
            Call <a className="phone-number" href="tel:888-345-4269">888-345-4269</a> to book your class today with promo code <span className="bold">24RIDHPH20</span>. 
          </p>
          <p>
            Must purchase gift card by 12/31/24. Not valid on Private Training or Two-day MSF Basic Rider Course. Valid at our South Carolina location.
          </p>
        </FlexCard>
      )
    } else {
      return (
        <FlexCard
        rowType="row"
        image={giftcardimage}
        imageAlt="GIVE THE GIFT OF SPEED"
        header="GIVE THE GIFT OF SPEED"
        subheader="BMW U.S. RIDER ACADEMY GIFT CARDS"
        text="Give someone a driving gift they’ll never forget. Good toward any class, each BMW gift card is delivered electronically, so it’s also perfect for last-minute gift giving."
        btnText="Call 888-345-4269 to get yours now"
        btnStyle="btn-blue"
      >
        <a href='tel:888-345-4269' className='phone-number' target="_blank" rel="noopener noreferrer">
          <button className="btn btn-bordered">call 888-345-4269 to get yours now</button>
        </a>
      </FlexCard>
      )
    }
  }



  return (
    <Layout>
      <SEO
        title="The BMW U.S. Rider Academy | Motorrad Tours and Classes"
        description="The BMW U.S. Rider Academy offers custom motorrad tours, riding classes – on and off-road, of course, and the chance to ride and try the latest BMW motorcycles."
      />
      <div className="giftcardpage giftcardpagemotorrad">
          <GiftCardBackground
            imageDesktop={topbanner}
            imageMobile={topbanner}
            imageAlt="BMW Gift Card Top Banner"
          />
          <section className="drivingschool">
            <div className="container">
              <div className="performance-center__header">
                <h2>Gift Cards</h2>
              </div>
            </div>
            {getFirstCard()}
          </section>
        </div>
    </Layout>
  );
};

export default GiftCards;
